<template>
  <div>
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      scrollable
      persistent
    >
      <!-- Loading spinner -->
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          class="my-10"
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <!-- Dialog content -->
      <v-card v-else background-color="#f2f2f2">
        <v-card-title
          class="py-0 pt-7"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img src="@/assets/teamModalLogo.svg" height="85" width="100" />
          </div>
          <div class="ml-1 dialogTitle-text">View Assembly</div>
          <v-spacer></v-spacer>
          <v-icon
            text
            large
            color="white"
            style="
              cursor: pointer;
              position: relative;
              right: -15px;
              bottom: 40px;
            "
            @click="
              toggleViewAssemblyModal({ show: false });
            "
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <!-- Render each item from the items array -->
          <div >
           <!-- <div>
              <span v-if="item.player_list" style="font-size: 20px">
                {{ item.assemble_city }}, {{ item.assemble_state }} - FA({{
                  item.sequence
                }})
                <span
                  v-for="(player, playerIndex) in item.player_list"
                  :key="playerIndex"
                >
                  {{ player.name }},
                </span>
              </span>
              <span v-else style="font-size: 20px">
                {{ item.assemble_city }}, {{ item.assemble_state }} - FA({{
                  item.sequence
                }}) {{ item.team_name }}
              </span>
            </div>-->

            <div>
              <b>Date:</b> {{ this.assemblyDetail.assemble_date_2 }} <br />
              <b>Time:</b> {{ this.assemblyDetail.start_time_str }} - {{ this.assemblyDetail.end_time_str }}
              <br />
              <b>No. Of Assemblies:</b> {{ this.assemblyDetail.total_assemblies }} <br />
              <b>No. Of Students:</b> {{ this.assemblyDetail.total_student }} <br />
              <b>Meeting Time:</b> {{ this.assemblyDetail.meeting_time_str }} <br />
              <b>Meeting Place:</b> {{ this.assemblyDetail.school }} <br />
              <b>Meeting Address:</b> {{ this.assemblyDetail.address }} <br />
              <b>Driver Name:</b> {{ this.assemblyDetail.driver_name }} <br />
              <b>Driver Cell:</b> {{ this.assemblyDetail.phone }} <br />
              <b>Description:</b> <br />
              <div v-html="this.assemblyDetail.note" style="line-height: 1.2"></div>
            </div>

            <!-- Rendering multiple school groups -->
            <div
              v-for="(group, groupIndex) in this.assemblyDetail.assemble_school_groups"
              :key="groupIndex"
              class="mt-4"
            >
              <strong>School {{ groupIndex + 1 }}</strong> <br />
              <b>School Name:</b> {{ group.school_name }} <br />
              <b>School Address:</b> {{ group.school_address }} <br />
              <b>Start Time:</b> {{ group.start_time_str }} <br />
              <b>End Time:</b> {{ group.end_time_str }} <br />
              <b>Location:</b> {{ group.location_in_school }} <br />
              <b>Number of Students:</b> {{ group.number_of_students }} <br />
              <b>Principal Name:</b> {{ group.principal_name }} <br />
              <b>Principal Email:</b> {{ group.principal_email }} <br />
              <b>Principal Phone:</b> {{ group.principal_phone }} <br />
              <b>Grade:</b> {{ group.grades }} <br />
              <b>Visit:</b> {{ group.visit }} <br />
              <div v-html="'<strong>Note:</strong> ' + group.note"></div> <br /><br />
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Axios from "@/api/BaseAxios";
import { ASSEMBLY_SCHEDUAL_API_GET } from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
export default {
  name: "NoteModal",
  data() {
    return {
      rules: RULES,
      loading: false,
      assemblyDetail: {},
    };
  },
  computed: {
    ...mapGetters({
      showGetters: "gameManagement/getViewAssemblyShow",
      selectGameData: "gameManagement/getGame",
    }),
    show: {
      get() {
        return this.showGetters;
      },
      set(value) {
        return this.toggleViewAssemblyModal({ show: value });
      },
    },
    type() {
      return this.$store.state.gameManagement.viewmessageModal.type;
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions({
      toggleViewAssemblyModal: "gameManagement/toggleViewAssemblyModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getAssemblyData();
    },
    closeModal() {
      this.loading = false;
    },
    getAssemblyData() {
      this.loading = true;
      const successHandler = (res) => {
        this.assemblyDetail = res.data.assemble_detail;
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["assemble_id"] =
        this.$store.state.gameManagement.viewmessageModal.assemblyId;
      Axios.request_GET(
        ASSEMBLY_SCHEDUAL_API_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    reload() {
      location.reload();
    },
  },
  mounted() {},
};
</script>
<style scoped>
* {
  font-family: Lato;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.v-btn.dialogAction-btnText {
  border-radius: 10px;
}
.v-btn >>> span {
  letter-spacing: 0px;
}
</style>
